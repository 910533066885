import * as React from 'react'
import { Icon } from 'office-ui-fabric-react'
import * as Types from 'lib/types'
import { getScreenSize } from 'lib/utils'
import { boxShadow } from 'styles/constants'

declare global {
  interface Window {
    gtag: (...args: any[]) => void
  }
}

export const configurations: { [key: string]: Types.IConfiguration } = {
  covidVacine: {
    key: 'covidVacine',
    esIndex: 'covid-vacine',
    subtext: '#LIVEbyZoic',
    title: 'Covid Vacine',
    layerValues: [
      'domain',
      'entity',
      'location',
      'sentiment',
      'source',
      'username'
    ],
    layerMapping: {
      entity: 'Topic',
      Topic: 'entity'
    },
    defaultLayers: ['root', 'sentiment', 'domain', 'entity'],
    // defaultLayers: ['root', 'entity'],
    presets: [
      {
        id: 'National Sentiment',
        layers: ['sentiment', 'domain']
      },
      {
        id: 'National Sentiment by User',
        layers: ['sentiment', 'domain', 'username']
      }
    ],
    isLive: true,
    initialTimeRange: {
      start: new Date('7/13/2021').getTime(),
      end: new Date('8/15/2021').getTime()
    }
  },
  usElection2020: {
    key: 'usElection2020',
    esIndex: 'us-election-2020',
    subtext: '#LIVEbyZoic',
    title: 'US 2020 Election Twitter Sentiment',
    layerValues: [
      'domain',
      'entity',
      'location',
      'sentiment',
      'source',
      'username'
    ],
    layerMapping: {
      entity: 'Topic',
      Topic: 'entity'
    },
    defaultLayers: ['root', 'entity', 'domain', 'sentiment'],
    // defaultLayers: ['root', 'entity'],
    presets: [
      {
        id: 'National Sentiment',
        layers: ['entity', 'domain', 'sentiment']
      },
      {
        id: 'National Sentiment by Source',
        layers: ['source', 'entity', 'domain', 'sentiment']
      }
    ],
    isLive: false,
    initialTimeRange: {
      start: new Date('11/2/2020').getTime(),
      end: new Date('11/04/2020').getTime()
    }
  },
  ukElection2017: {
    key: 'ukElection2017',
    esIndex: 'uk-election-2017',
    title: 'UK 2017 Election Twitter Sentiment',
    subtext: '#LIVEbyZoic',
    layerValues: [
      'candidate',
      'sentiment',
      'emotion',
      'issue',
      'gender',
      'source'
    ],
    layerMapping: {
      source: 'Political Party',
      'Political Party': 'source'
    },
    defaultLayers: ['root', 'candidate', 'emotion'],
    presets: [
      {
        id: 'National Sentiment',
        layers: ['candidate', 'emotion']
      },
      {
        id: 'Demographic By Gender',
        layers: ['gender', 'candidate', 'emotion']
      },
      {
        id: 'Demographic By Political Party',
        layers: ['source', 'candidate', 'emotion']
      },
      {
        id: 'Demographic By Issue',
        layers: ['issue', 'candidate', 'emotion']
      }
    ],
    isLive: false,
    initialTimeRange: {
      start: new Date('06/07/2017').getTime(),
      end: new Date('06/09/2017').getTime()
    }
  },
  usElection2016: {
    key: 'usElection2016',
    esIndex: 'us-election-2016',
    title: 'US 2016 Election Twitter Sentiment',
    subtext: '#LIVEbyZoic',
    layerMapping: {
      ageBracket: 'Age Bracket',
      'Age Bracket': 'ageBracket'
    },
    layerValues: [
      'candidate',
      'sentiment',
      'emotion',
      'gender',
      'source',
      'ageBracket'
    ],
    defaultLayers: ['root', 'candidate', 'emotion'],
    presets: [
      {
        id: 'National Sentiment',
        layers: ['candidate', 'emotion']
      },
      {
        id: 'Demographic By Gender',
        layers: ['gender', 'candidate', 'emotion']
      },
      {
        id: 'Demographic By Source',
        layers: ['source', 'candidate', 'emotion']
      },
      {
        id: 'Demographic By Age',
        layers: ['ageBracket', 'candidate', 'emotion']
      }
    ],
    isLive: false,
    initialTimeRange: {
      start: new Date('11/07/2016').getTime(),
      end: new Date('11/09/2016').getTime()
    }
  },
  christmas2020: {
    key: 'christmas2020',
    esIndex: 'christmas-2020',
    title: 'Christmas 2020',
    subtext: '#LIVEbyZoic',
    layerMapping: {
      entity: 'Topic',
      Topic: 'entity'
    },
    layerValues: [
      'sentiment',
      'entity',
      'username',
      'source',
      'location',
      'domain'
    ],
    defaultLayers: ['root', 'domain', 'entity', 'sentiment'],
    presets: [
      {
        id: 'Christmas Spirit',
        layers: ['domain', 'entity', 'sentiment']
      }
    ],
    isLive: false,
    initialTimeRange: {
      start: new Date('12/07/2020').getTime(),
      end: new Date('1/04/2021').getTime()
    }
  },
  georgia2021: {
    key: 'georgia2021',
    esIndex: 'georgia-2021',
    title: 'Georgia 2021',
    subtext: '#LIVEbyZoic',
    layerMapping: {
      entity: 'Topic',
      Topic: 'entity'
    },
    layerValues: [
      'domain',
      'entity',
      'location',
      'sentiment',
      'source',
      'username'
    ],
    defaultLayers: ['root', 'entity', 'domain', 'sentiment'],
    presets: [
      {
        id: 'National Sentiment',
        layers: ['entity', 'domain', 'sentiment']
      },
      {
        id: 'National Sentiment by Source',
        layers: ['source', 'entity', 'domain', 'sentiment']
      }
    ],
    isLive: false,
    initialTimeRange: {
      start: new Date('01/04/2021').getTime(),
      end: new Date('1/07/2021').getTime()
    }
  }
}

const initialConfiguration = configurations['covidVacine']

export const initialState: Types.Context = {
  configuration: initialConfiguration,
  windowDimensions: {
    height: window.innerHeight,
    width: window.innerWidth
  },
  sendEvent: (eventName, values) => {
    window.gtag('event', eventName, values)
  },
  layerValues: initialConfiguration.layerValues,
  isQueryInProgress: false,
  petri: {
    breadcrumb: ['root'],
    clear: false,
    layers: initialConfiguration.defaultLayers,
    onLastLayer: false,
    isNormalized: false,
    population: [],
    aggregations: [],
    timeFilter: initialConfiguration.isLive ? 'Breaking News' : 'All News',
    timeRange: { start: null, end: null, field: '' },
    currentTimeRange: { start: null, end: null, field: '' },
    actions: {
      updateBreadcrumb: () => {},
      updateLayers: () => {},
      updateNormalization: () => {},
      updateTimeFilter: () => {},
      updateCurrentTimeRange: () => {}
    }
  },
  screenSize: getScreenSize({}),
  timeFilterOptions: ['Breaking News', "Today's News", 'All News'],
  actions: {
    performQuery: () => {},
    updateConfiguration: () => {}
  },
  refs: {
    panelRef: null
  },
  tutorial: {
    startButtonId: 'tutorialButton',
    steps: [
      {
        index: 0,
        cardTarget: '#tutorialButton',
        indicatorTarget: null,
        align: (isMobile) => (isMobile ? 'centerBottom' : 'leftTop'),
        headline: 'Question 1 (Navigating the Petri):',
        content:
          'Among politicians, what was the largest sentiment for Donald Trump?',
        hint: 'You don’t need to change any defaults to find the answer!',
        successText: 'Correct!',
        answers: ['Positive', 'Negative', 'Neutral', 'Mixed'],
        answer: 'Neutral'
      },
      {
        index: 1,
        cardTarget: '#tutorialButton',
        indicatorTarget: '#addGroupDropdown',
        align: (isMobile) => (isMobile ? 'centerBottom' : 'leftTop'),
        headline: 'Question 2 (Adding/removing layers)',
        content:
          'In Los Angeles, what application was used to send the most tweets?',
        hint: 'Try changing which groups you’re viewing',
        successText: 'Correct!',
        answers: ['Android app', 'iPhone app', 'Web app', 'TweetDeck'],
        answer: 'iPhone app'
      },
      {
        index: 2,
        cardTarget: '#tutorialButton',
        indicatorTarget: null,
        align: (isMobile) => (isMobile ? 'centerBottom' : 'leftTop'),
        headline: 'Question 3 (time controls)',
        content:
          'Which location group grew to the largest number of tweets at around 3:45 am on 11/3?',
        // @ts-ignore
        hint: (isMobile) =>
          isMobile ? (
            <>
              Open the time slider using the
              <Icon
                iconName={'DateTime'}
                styles={{
                  root: {
                    backgroundColor: 'var(--neutralLight)',
                    padding: 4,
                    borderRadius: '50%',
                    margin: '0px 4px',
                    top: -2,
                    position: 'relative',
                    boxShadow: boxShadow.elevation4
                  }
                }}
              />
              in the lower right
            </>
          ) : (
            'Control the time range using the orange dot on top of the petri'
          ),

        successText: 'Correct!',
        answers: [
          'London',
          'India',
          'Washington, DC',
          'New York and the World'
        ],
        answer: 'New York and the World'
      }
    ]
  }
}

export const AppContext = React.createContext<Types.Context>(initialState)
